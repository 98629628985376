<template>
  <article class="blog">
    <h2 class="blog__title">
      {{ title }}
    </h2>
    <div class="blog__box">
      <span class="blog__created-at">{{ formatToMonth(new Date(createdAt)) }}</span>
      <span>&bull;</span>
      <span class="blog__views">
        <icon
          :type="IconType.Contour"
          :name="IconName.Eye"
          :size="Size.Xxs"
        />
        {{ viewsCount }}
      </span>
    </div>
    <img
      v-if="imagePath"
      :alt="title"
      class="blog__image"
      :src="imagePath"
    >

    <div
      class="blog__content"
      v-html="limitedContent"
    />

    <button
      :class="['blog__button', isActive && 'blog__button-active']"
      @click="emit('toggle')"
    >
      {{ isActive ? $t('pages.blog.readMoreHide') : $t('pages.blog.readMore') }}

      <icon
        :type="IconType.Contour"
        :name="IconName.ArrowUncover"
        :size="Size.Xs"
      />
    </button>
  </article>
</template>

<script lang="ts" src="./blog.ts" />
<style lang="scss" src="./blog.scss" />
