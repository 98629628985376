import { render } from "./Blog.vue?vue&type=template&id=4f07928f"
import script from "./blog.ts?vue&type=script&lang=ts"
export * from "./blog.ts?vue&type=script&lang=ts"

import "./blog.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4f07928f"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4f07928f', script)) {
    api.reload('4f07928f', script)
  }
  
  module.hot.accept("./Blog.vue?vue&type=template&id=4f07928f", () => {
    api.rerender('4f07928f', render)
  })

}

script.__file = "src/components/Blog/Blog.vue"

export default script