import { render } from "./Blog.vue?vue&type=template&id=71993c76"
import script from "./blog.ts?vue&type=script&lang=ts"
export * from "./blog.ts?vue&type=script&lang=ts"

import "./blog.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "71993c76"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('71993c76', script)) {
    api.reload('71993c76', script)
  }
  
  module.hot.accept("./Blog.vue?vue&type=template&id=71993c76", () => {
    api.rerender('71993c76', render)
  })

}

script.__file = "src/views/Blog/Blog.vue"

export default script