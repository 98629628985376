import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { IconType, IconName } from 'components/Icon';
import { Size } from 'core/styles';

import sanitizeHtml from 'sanitize-html';
import { useApiErrors } from 'composables/apiErrors';
import { RouteNames } from 'router/names';
import Blog from 'components/Blog';
import { IBlog } from 'api/blogs/models';
import { Api } from 'api/api';
import MainLayout from 'layouts/MainLayout';
import ListLayout from 'layouts/ListLayout';
import MainPageFooter from 'views/Main/components/MainPageFooter';
import Spinner from 'modules/cropper/Spinner.vue';

const Blogs = defineComponent({
  name: RouteNames.Blogs,
  components: {
    MainLayout,
    ListLayout,
    MainPageFooter,
    Blog,
    Spinner,
  },
  setup() {
    const router = useRouter();
    const { showError } = useApiErrors();
    const scrollComponent = ref();
    const blogs = ref<IBlog[]>([]);
    const currentPage = ref(1);
    const lastPage = ref(0);
    const isLoading = ref(false);
    const activeId = ref();

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
      fetchBlogs();
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    function handleScroll() {
      const element = scrollComponent.value;
      if (
        element && element.getBoundingClientRect().bottom < window.innerHeight &&
        !isLoading.value && currentPage.value <= lastPage.value
      ) fetchBlogs();
    }

    async function fetchBlogs() {
      try {
        isLoading.value = true;
        const { data: { data, meta } } = await Api.blogsService.fetchBlogs(currentPage.value);
        blogs.value = blogs.value.concat(data);
        lastPage.value = meta.last_page;
        currentPage.value++;
      } catch (error) {
        showError(error);
      } finally {
        isLoading.value = false;
      }
    }
    async function goToShowBlog(blogSlug: string) {
      const cleanedBlogSlug = blogSlug.replace(/\//g, '');
      router.push({
        name: RouteNames.BlogsShow,
        params: {
          slug: cleanedBlogSlug,
        },
      });
    }
    function onShowBlogClick(blogSlug: string) {
      goToShowBlog(blogSlug);
    }

    function toggleActive(id: number) {
      activeId.value = activeId.value === id ? null : id;
    }

    return {
      IconType,
      IconName,
      Size,

      sanitizeHtml,

      blogs,
      isLoading,
      activeId,
      scrollComponent,
      onShowBlogClick,
      fetchBlogs,
      toggleActive,
    };
  },
});

export default Blogs;
