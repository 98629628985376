import dayjs from 'dayjs';

export const formatTo24Hours = (time: Date): string =>
  time.toLocaleTimeString(navigator.language, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

export const formatToChat = (date: Date): string => {
  const obj = dayjs(date);
  if (obj.isToday()) {
    return obj.format('HH:mm');
  }

  if (obj.isAfter(dayjs().weekday(0))) {
    return dayjs(date).format('dd');
  }

  return dayjs(date).format('DD.MM.YYYY');
};

export const formatToMonth = (date: Date): string => dayjs(date).format('DD MMMM');
