import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { IconType, IconName } from 'components/Icon';
import { Size } from 'core/styles';

import { useApiErrors } from 'composables/apiErrors';
import { RouteNames } from 'router/names';
import Blog from 'components/Blog';
import { IBlog } from 'api/blogs/models';
import { Api } from 'api/api';
import MainLayout from 'layouts/MainLayout';
import ListLayout from 'layouts/ListLayout';
import MainPageFooter from 'views/Main/components/MainPageFooter';
import Spinner from 'modules/cropper/Spinner.vue';

const Blogs = defineComponent({
  name: RouteNames.BlogsShow,
  components: {
    MainLayout,
    ListLayout,
    MainPageFooter,
    Blog,
    Spinner,
  },
  setup() {
    const router = useRouter();
    const { showError } = useApiErrors();
    const blog = ref<IBlog>();
    const isLoading = ref(false);

    async function fetchBlog() {
      try {
        isLoading.value = true;
        const blogSlug: string = Array.isArray(router.currentRoute.value?.params?.slug)
          ? router.currentRoute.value.params.slug[0]
          : router.currentRoute.value?.params?.slug ?? '';
        const { data: { data } } = await Api.blogsService.fetchBlogBySlug(blogSlug);
        blog.value = data;
      } catch (error) {
        showError(error);
      } finally {
        isLoading.value = false;
      }
    }
    async function goToListBlog() {
      router.push({
        name: RouteNames.Blogs,
      });
    }
    function onListBlogClick() {
      goToListBlog();
    }
    onMounted(fetchBlog);

    return {
      IconType,
      IconName,
      Size,
      blog,
      onListBlogClick,
    };
  },
});

export default Blogs;
