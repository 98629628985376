// import { defineComponent, ref, watch } from 'vue';
import { defineComponent, onBeforeMount, ref, watch } from 'vue';
import { IconType, IconName } from 'components/Icon';
import { Size } from 'core/styles';
import { formatToMonth } from 'core/date-time';

import sanitizeHtml from 'sanitize-html';
import { Api } from 'api/api';

const Blog = defineComponent({
  props: {
    id: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    imagePath: {
      type: String,
      default: '',
    },
    viewsCount: {
      type: Number,
      required: true,
    },
    createdAt: {
      type: String,
      required: true,
    },
  },
  emits: ['toggle'],
  setup(props, { emit }) {
    const content = ref('');
    const limitedContent = ref(props.content);
    watch(() => props.isActive, (isActive) => {
      if (isActive) {
        limitedContent.value = content.value;
      } else {
        limitedContent.value = props.content;
      }
    });

    async function fetchContent() {
      const { data: { data } } = await Api.blogsService.fetchBlogById(props.id);
      content.value = sanitizeHtml(data.content);
    }
    if (props.isActive) {
      onBeforeMount(fetchContent);
    }

    return {
      IconType,
      IconName,
      Size,
      formatToMonth,

      limitedContent,
      fetchContent,
      emit,
    };
  },
});

export default Blog;
