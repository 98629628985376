<template>
  <main-layout>
    <list-layout
      :title="$t('pages.catalog.title')"
    >
      <template #body>
        <div
          ref="scrollComponent"
          class="blogs"
        >
          <blog
            v-for="blog in blogs"
            :id="blog.id"
            :key="blog.id"
            :title="blog.title"
            :content="blog.content"
            :image-path="blog.main_image_path"
            :views-count="blog.views_count"
            :created-at="blog.created_at"
            :is-active="activeId === blog.id"
            @toggle="onShowBlogClick(blog.slug)"
          />
          <spinner
            v-if="isLoading"
            class="blogs__spinner"
          />
        </div>
        <main-page-footer />
      </template>
    </list-layout>
  </main-layout>
</template>

<script lang="ts" src="./blogs.ts" />
<style lang="scss" src="./blogs.scss" />
