<template>
  <main-layout>
    <list-layout
      :title="$t('pages.catalog.title')"
    >
      <template #body>
        <div class="blogs">
          <blog
            v-if="blog"
            :id="blog.id"
            :key="blog.id"
            :title="blog.title"
            :content="blog.content"
            :image-path="blog.main_image_path"
            :views-count="blog.views_count"
            :created-at="blog.created_at"
            :is-active="true"
            @toggle="onListBlogClick()"
          />
          <spinner
            v-if="isLoading"
            class="blogs__spinner"
          />
        </div>
        <main-page-footer />
      </template>
    </list-layout>
  </main-layout>
</template>

<script lang="ts" src="./blog.ts" />
<style lang="scss" src="./blog.scss" />
